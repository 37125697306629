import type { GetServerSideProps, NextPage } from 'next';
import { CreditCardGuarantee } from '@/components/CreditCardGuarantee/CreditCardGuarantee';
import { ContactForm } from '@/components/ContactForm/ContactForm';
import { DateSelector } from '@/components/DateSelector/DateSelector';
import { OfferSelector } from '@/components/OfferSelector/OfferSelector';
import { PartySizeSelector } from '@/components/PartySizeSelector/PartySizeSelector';
import { TimeslotSelector } from '@/components/TimeslotSelector/TimeslotSelector';
import { ErrorStep } from '@/components/ErrorStep/ErrorStep';
import { WizardLayout } from '@/components/WizardLayout/WizardLayout';
import { WidgetConfig } from '@/contexts/WidgetConfigProvider/WidgetConfigProvider';
import { getSdk } from '@/graphql/client-backend.generated';
import { WizardStep } from '@/types/WizardBooking';
import { getApolloClientForBackend } from '@/utils/apollo-client-utils';
import { calculateBookingState, useBooking } from '@/hooks/useBooking';
import { useIntl } from 'react-intl';
import { PaymentContextProvider } from '@/contexts/PaymentContext/PaymentContext';
import { getPaymentConfig } from '@/utils/payment-utils';
import { SuccessStep } from '@/components/SuccessStep/SuccessStep';
import { GetInitialDataWithFacebookQuery } from '@/graphql/types.generated';
import { ApolloQueryResult, ErrorPolicy } from '@apollo/client';
import { ResultStep } from '@/components/ResultStep/ResultStep';
import { StartingStep } from '@/components/StartingStep/StartingStep';
import { validate as isValidUUID } from 'uuid';
import { Global, css } from '@emotion/react';
import { DEFAULT_FONT, buildFontsGlobalStyle } from '@/utils/theme/font-utils';
import { CouponRecapStep } from '@/components/CouponRecapStep/CouponRecapStep';
import { PlaygroundStep } from '@/components/PlaygroundStep/PlaygroundStep';
import { ErrorBoundary } from '@/components/ErrorBoundary/ErrorBoundary';
import useFacebookIntegration from '@/hooks/useFacebookIntegration';
import logger from '@/utils/logger';
import Head from 'next/head';
import * as S from './index.styles';
import { useSetReferrerInQueryUrl } from '@/hooks/useUrlQueryParams';
import { MoreInfoForm } from '@/components/MoreInfoForm/MoreInfoForm';
import { PaymentData } from '@/types/payment';
import { getZonedDateTimeFromDateAndTimeslot } from '@/utils/date-utils';
import React, { useEffect, useMemo } from 'react';
import { useErrorModal } from '@/contexts/ErrorModalContext/ErrorModalContext';
import ExclamationmarkCircle from '@lafourchette/react-chili/dist/cjs/components/Atoms/Icons/ExclamationmarkCircle';
import { useRouter } from 'next/router';

type BookingWizardProps = {
  hasDoubleBooking: boolean;
  config: WidgetConfig;
  paymentConfig: PaymentData;
};

const BookingWizard: NextPage<BookingWizardProps> = ({ config, paymentConfig, hasDoubleBooking }) => {
  const intl = useIntl();
  const router = useRouter();
  const { step, handleErrorGoBack } = useBooking();
  const { showErrorModal } = useErrorModal();
  const { openGraph, pixelScript } = useFacebookIntegration(config);
  useSetReferrerInQueryUrl();

  let fontsFaces = '';
  if (config.designKit) {
    fontsFaces = buildFontsGlobalStyle({
      heading: config.designKit.fonts.heading || DEFAULT_FONT,
      body: config.designKit.fonts.body || DEFAULT_FONT,
    })?.join('\n');
  }

  const errorModalConfig = useMemo(
    () => ({
      errorMessage: intl.formatMessage({
        id: 'tf_widget_double_reservation_error_message',
        defaultMessage: 'You already have a reservation for the same day and meal.',
      }),
      errorInfo: intl.formatMessage({
        id: 'tf_widget_double_reservation_error_info',
        defaultMessage: 'Select another date to finalize your booking.',
      }),
      onCtaClick: async () => {
        await router.push({
          pathname: config.restaurant?.restaurantUuid,
          query: {
            step: WizardStep.Date,
          },
        });
      },
      ctaText: intl.formatMessage({
        id: 'tf_widget_double_reservation_error_cta_text',
        defaultMessage: 'Change date',
      }),
      icon: ExclamationmarkCircle,
    }),
    [config.restaurant?.restaurantUuid, intl, router],
  );

  useEffect(() => {
    if (!hasDoubleBooking) return;
    showErrorModal(errorModalConfig);
  }, [errorModalConfig, hasDoubleBooking, showErrorModal]);

  return (
    <>
      <Head>
        <title>{intl.formatMessage({ id: 'tf_widget_booking_wizard', defaultMessage: 'Booking wizard' })}</title>
        <meta name="description" content="Booking wizard" />
        {/* TODO : This css style is important for Mac browser to not display white horizontal bar during scrolling */}
        {/* This css style can not be applied in a higher level because we don't want it for the horizontal / vertical display (ctabooking.page) */}
        {config?.designKit?.colors.background && (
          <style data-emotion="css-global" data-s="">
            {`html{background-color:${config?.designKit?.colors.background} !important}`}
          </style>
        )}
        <Global
          styles={css`
            ${fontsFaces}
          `}
        />
        {openGraph}
      </Head>

      <PaymentContextProvider data={paymentConfig}>
        {step === WizardStep.Success && (
          <ErrorBoundary module="successStep" handleGoBack={handleErrorGoBack}>
            <SuccessStep />
          </ErrorBoundary>
        )}
        {step !== WizardStep.Success && (
          <WizardLayout
            title={config.restaurant?.name || ''}
            isCouponEnabled={config.restaurant?.isCouponEnabled || false}
          >
            <S.Main>
              {(() => {
                switch (step) {
                  case WizardStep.Playground:
                    return <PlaygroundStep />;
                  case WizardStep.Blank:
                    return <StartingStep />;
                  case WizardStep.Pax:
                    return <PartySizeSelector />;
                  case WizardStep.Date:
                    return <DateSelector />;
                  case WizardStep.Hour:
                    return <TimeslotSelector />;
                  case WizardStep.CouponRecap:
                    return <CouponRecapStep />;
                  case WizardStep.Result:
                    return <ResultStep />;
                  case WizardStep.Offer:
                    return <OfferSelector />;
                  case WizardStep.UserInformation:
                    return <ContactForm />;
                  case WizardStep.MoreInfo:
                    return <MoreInfoForm />;
                  case WizardStep.Payment:
                    return <CreditCardGuarantee />;
                  case WizardStep.Error:
                    return <ErrorStep />;
                  default:
                    return <StartingStep />;
                }
              })()}
            </S.Main>
          </WizardLayout>
        )}
      </PaymentContextProvider>
      {pixelScript}
    </>
  );
};

export const getServerSideProps: GetServerSideProps<BookingWizardProps> = async (context) => {
  try {
    const {
      restaurantUuid,
      bookingParams: { pax, time, date, offer, couponCode, reservationIntentUuid, customerUuid },
      step,
    } = calculateBookingState(context.query);

    // Prevents from calling services with a wrong UUID.
    if (!restaurantUuid || !isValidUUID(restaurantUuid)) {
      logger.error(new Error('Invalid restaurantUuid'));
      return {
        notFound: true,
      };
    }

    let hasDoubleBooking = false;
    let paymentConfig: PaymentData = { needsPayment: false };
    const apolloClient = getApolloClientForBackend(context.locale);
    const graphQlSdk = getSdk(apolloClient);

    const isFacebookOrigin = context.query.origin === 'facebook';

    const errorPolicy: ErrorPolicy = 'all';
    const queryOptions = {
      variables: {
        restaurantUuid,
      },
      errorPolicy,
    };
    let initialData: ApolloQueryResult<GetInitialDataWithFacebookQuery>;
    if (isFacebookOrigin) {
      initialData = await graphQlSdk.getInitialDataWithFacebookQuery(queryOptions);
    } else {
      initialData = await graphQlSdk.getInitialDataQuery(queryOptions);
    }

    if (!initialData.data.restaurant) {
      logger.error(new Error('Missing restaurant data'));
      return {
        notFound: true,
      };
    }
    if (!initialData.data.restaurant.widgetSetting) {
      logger.error(new Error('Missing restaurant widget setting'));
      return {
        notFound: true,
      };
    }

    if (
      pax &&
      time &&
      date &&
      [WizardStep.Offer, WizardStep.Payment, WizardStep.UserInformation, WizardStep.MoreInfo].includes(step) // payment data is needed only for these steps
    ) {
      paymentConfig = await getPaymentConfig({
        graphQlSdk,
        restaurantUuid,
        pax,
        time,
        date,
        offer,
        couponCode,
        reservationIntentUuid,
        setupAuthData: step === WizardStep.Payment, // setupAuthData is needed only if the page is reloading on the payment step
      });
    }

    if (time && date && restaurantUuid && customerUuid) {
      const formattedDate = getZonedDateTimeFromDateAndTimeslot(date, time);
      if (formattedDate) {
        const dateString = formattedDate.toISOString();
        const result = await graphQlSdk.checkForDoubleReservationQuery({
          variables: {
            input: {
              restaurantUuid,
              dateTime: dateString,
              offlineCustomerUuid: customerUuid,
            },
          },
        });
        hasDoubleBooking = result.data.checkForDoubleReservation.hasDoubleReservation;
      }
    }

    return {
      props: {
        hasDoubleBooking,
        paymentConfig,
        config: {
          restaurant: initialData.data.restaurant || null,
          settings: initialData.data.restaurant?.widgetSetting || null,
          facebookIntegration: initialData.data.restaurant?.facebookIntegration || null,
          designKit: initialData.data.restaurant?.designKit || null,
        },
      },
    };
  } catch (error) {
    logger.error(error);
    return {
      notFound: true,
    };
  }
};

export default BookingWizard;
