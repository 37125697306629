import React from 'react';
import { CreditCardGuaranteeForm } from '@/components/CreditCardGuarantee/CreditCardGuaranteeForm/CreditCardGuaranteeForm';
import { usePaymentContext } from '@/contexts/PaymentContext/PaymentContext';

export const GUARANTEE_TYPE = {
  IMPRINT: 'CCG',
  PREPAYMENT: 'PPM',
} as const;
type GUARANTEE_TYPE_Keys = keyof typeof GUARANTEE_TYPE;
export type GUARANTEE_TYPE_Values = (typeof GUARANTEE_TYPE)[GUARANTEE_TYPE_Keys];

export const getStripeAction = (guaranteeType: GUARANTEE_TYPE_Values) =>
  guaranteeType === GUARANTEE_TYPE.PREPAYMENT ? 'confirmPayment' : 'confirmSetup';

export const CreditCardGuaranteeWithStripe: React.FC = () => {
  const paymentData = usePaymentContext();

  if (!paymentData.config) {
    return null;
  }

  const {
    config: {
      paymentProvider: { authData: stripeAuth },
    },
  } = paymentData;

  return (
    <CreditCardGuaranteeForm
      stripeClientSecret={stripeAuth?.paymentGuaranteeIntent?.stripeAuthData?.stripeIntentClientSecret ?? ''}
    />
  );
};
